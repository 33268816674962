import React from "react"
import { Link } from "gatsby"
// import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import "./Badge.scss"

const defaultBackgroundColor = "#2be1f3"

export default function Badge({ badgeData, wrapLine }) {
  const renderBadgeItem = (badge) => {
    const backgroundColor = badge?.CategoryCustomFields?.color || defaultBackgroundColor

    return (
      <div className="customBadge" style={{ backgroundColor: backgroundColor }}>
        <Link to={`/categories/${badge.slug}`}>
          <p>{badge?.name}</p>
        </Link>
      </div>
    )
  }

  return (
    <div className="custom-badge-wrap" style={{ flexWrap: wrapLine ? 'wrap' : 'nowrap'}}>
      {badgeData?.map(renderBadgeItem)}
    </div>
  )
}
